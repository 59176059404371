<template>
    <div>
        <div class="card-toolbar mb-5">
            <button v-if="$can('cities.create')"  @click="showModal" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon>
                {{ $t('cities.new_city') }}
            </button>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label for="name">{{$t('name')}}</label>
                            <input v-model="filters.name" type="text" id="name" class="form-control">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="code2">{{$t('code2')}}</label>
                            <input v-model="filters.code2" type="text" id="code2" class="form-control">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="notes">{{$t('notes')}}</label>
                            <input v-model="filters.notes" type="text" id="notes" class="form-control">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="name">{{$t('status')}}</label>
                            <select name="" id="status" v-model="filters.status" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{$t('search')}}</span>
                                </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{$t('reset_search')}}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
                    <template slot="status" slot-scope="props">
                        <b-form-checkbox  v-if="$can('cities.change_status')"
                            size="lg" @change="changeStatus(props.row.id, props.row.status)" 
                            v-model="props.row.status" 
                            :name="'check-button'+props.row.id" 
                            switch :key="props.row.id">
                        </b-form-checkbox>
                        <b-form-checkbox  v-else
                            size="lg" :disabled="true"
                            v-model="props.row.status"
                            :name="'check-button'+props.row.id"
                            switch :key="props.row.id">
                        </b-form-checkbox>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2"  v-if="$can('cities.update')" @click="showModal(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2"  v-if="$can('cities.delete')" @click="deletecity(props.row)">mdi-delete</v-icon>
                    </template>
                </v-server-table>
                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->

        <b-modal ref="modal" hide-footer :title="$t('cities.city')">
          <div class="row">
            <div class="col-md-6 mb-5">
              <label for="en_name">
                {{ $t('cities.en_name') }}<span class="text-danger">*</span>
              </label>
              <input v-model="city.en_name" type="text" id="en_name" class="form-control" :class="validation && validation.en_name ? 'is-invalid' : ''">
              <span v-if="validation && validation.en_name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.en_name[0] }}
                </span>
            </div>
            <div class="col-md-6 mb-5">
              <label for="ar_name">
                {{ $t('cities.ar_name') }}<span class="text-danger">*</span>
              </label>
              <input v-model="city.ar_name" type="text" min="1" id="ar_name" class="form-control" :class="validation && validation.ar_name ? 'is-invalid' : ''">
              <span v-if="validation && validation.ar_name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.ar_name[0] }}
                </span>
            </div>
            <div class="col-md-6 mb-5">
              <label>{{$t('status')}}:</label>
              <b-form-checkbox size="lg" v-model="city.status" name="check-button" switch></b-form-checkbox>
            </div>
            
          </div>




            <!-- <div class="form-group">
                <label for="name">
                    {{ $t('cities.code2') }}<span class="text-danger">*</span>
                </label>
                <input v-model="city.code2" type="text" id="name" class="form-control" :class="validation && validation.code2 ? 'is-invalid' : ''">
                <span v-if="validation && validation.code2" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.code2[0] }}
                </span>
            </div> 

            <div class="form-group">
                <label for="name">
                    {{ $t('cities.code3') }}<span class="text-danger">*</span>
                </label>
                <input v-model="city.code3" type="text" id="name" class="form-control" :class="validation && validation.code3 ? 'is-invalid' : ''">
                <span v-if="validation && validation.code3" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.code3[0] }}
                </span>
            </div> -->



            <div class="d-flex justify-content-end">
                <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{$t('close')}}</b-button>
                <b-button class="mt-2 mr-1" variant="primary" @click="save">{{$t('save')}}</b-button>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "city-index",
        components: {},
        data() {
            return {
                city: {
                    en_name: '',
                    ar_name: '',
                    code2: '',
                    code3: '',
                    status: true,
                },
                showAdvancedSearch: false,
                filter: {
                    sortBy: 'id',
                },
                filters: {
                    name: '',
                    status: '',
                    notes: '',
                    code2: '',
                },
                columns: ['en_name','ar_name', 'code2','status', 'actions'],
                validation: null,
                mainRoute:'settings/cities',
                routeChangeStatus : 'settings/city/change-status',
                isEditing: false,
                id: null,
                countryCode: this.$route.params.code2 ? this.$route.params.code2 : '',
                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],

            }
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter')+' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading')+"...",
                    },
                    headings: {
                        en_name: that.$t('cities.en_name'),
                        ar_name: that.$t('cities.ar_name'),

                        code2: that.$t('cities.code2'),
                        code3: that.$t('cities.code3'),

                        status: that.$t('status'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['en_name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',
                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            countryCode: that.countryCode,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }
            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.Countries'), route: '/settings/countries'}, {title: this.$t("MENU.cities_management")}]);
            let that = this;
            this.city.code2 = that.countryCode;
            this.city.code3 = that.countryCode;
        },
        methods: {
            changeStatus(id, status) {
                ApiService.patch(`${this.routeChangeStatus}/${id}`, {
                  status: (status ? 1 : 0),
                }).then((response) => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch((error) => {
                    this.$errorAlert(error);
                });
            },

            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = '';
                this.filters.code2 = '';
                this.filters.code3 = '';
                this.filters.status = '';
                this.filters.code2= '';

                this.$refs.table.refresh();
            },
            save() {
                if (this.isEditing) {
                    this.updatecity();
                } else {
                    this.savecity();
                }
            },
            getFetch() {
                this.$refs.table.refresh();
            },
            actionDelete(city){
                ApiService.delete(`${this.mainRoute}/${city.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error)
                })
            },
            deletecity(city) {
                this.$confirmAlert('', this.actionDelete, city);
            },

            showModal(data) {
                this.afterSavecity();
                if (!data.id) {
                    this.$refs['modal'].show();
                    this.afterSavecity();
                }else{
                    this.isEditing =true;
                    this.id = data.id;
                    this.getData(data);
                    this.$refs['modal'].show();
                }
            },
            hideModal() {
                this.$refs['modal'].hide();
            },
            toggleModal() {
                this.$refs['modal'].toggle('#toggle-btn')
            },
            savecity() {
                this.city.code2 = this.countryCode;
                this.city.code3 = this.countryCode;
                ApiService.post(this.mainRoute, this.city).then((response) => {
                    this.$successAlert(response.data.message);
                    this.afterSavecity();
                }).catch((errors) => {
                    this.validation = errors.response ? errors.response.data.errors : null;
                });
            },
            updatecity() {
                this.city.code2 = this.countryCode;
                this.city.code3 = this.countryCode;
                ApiService.put(`${this.mainRoute}/${this.id}`, {
                    ...this.city,
                }).then((response) => {
                    this.$successAlert(response.data.message);
                    this.validation = null;
                    this.afterSavecity();
                })
                .catch((errors) => {
                    this.validation = errors.response ? errors.response.data.errors : null;
                });
            },
            getData(data) {
                this.city.ar_name = data.ar_name;
                this.city.en_name = data.en_name;
                this.city.code2 = data.code2;
                this.city.code3 = data.code3;
                this.city.status = data.status;
            },
            afterSavecity() {
                this.city = {
                    ar_name: '',
                    en_name: '',
                    code2: '',
                    code3: '',
                    status: '',
                };
                this.hideModal();
                this.getFetch();
                this.validation = [];
            }
        },
    };
</script>
